




























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";
import { saveAs } from "file-saver";
import { createReportHeaders } from "../reports/reportHelpers";
import { legalServicesReportOptions } from "../reports/reportsSelectOptions";

@Component({
  components: {
    DatePicker
  }
})
export default class LegalservicesReport extends Vue {
  @Prop()
  public reportType!: string;

  @Prop()
  public title!: string;

  private from = " ";
  private to = " ";
  private reportItems: any = [];
  private items = [];
  private options = legalServicesReportOptions;
  private selectAll = false;

  created() {
    const currentDate = new Date();
    this.to = currentDate.toISOString().split("T")[0];
    currentDate.setMonth(currentDate.getMonth() - 1);
    this.from = currentDate.toISOString().split("T")[0];

    if (!this.reportType) {
      this.$router.push({ name: "Reports" });
    }
  }
  async addReportItem() {
    this.reportItems = [];
    this.items.forEach(async (i: any) => {
      const reportFilters = {
        ReportName: i.value,
        ReportType: this.reportType,
        StartDate: this.from,
        EndDate: this.to
      };
      const data = await this.$service.getReport(
        "Reports/" + this.reportType,
        reportFilters
      );

      const columns = createReportHeaders(data);

      this.reportItems.push({
        name: data.name,
        columns: columns,
        data: data.values
      });
    });
  }

  async download() {
    const reportFilters = {
      ReportsToDownload: this.items.map((x: any) => x.value),
      ReportType: this.reportType,
      StartDate: this.from,
      EndDate: this.to,
      Download: true
    };
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(await this.$service.downloadReports(reportFilters), 
    `Legal_Services_Report_${utc}.xlsx`);
  }
}
